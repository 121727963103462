import React from "react";
import classNames from "classnames";

import Logo from "../Logo";

import facebook from "./images/facebook.svg";
import insta from "./images/insta.svg";

import * as styles from "./styles.module.scss";

const SOCIAL_LINKS = [
  {
    name: "Facebook",
    link: "https://www.facebook.com/1493776157328385",
    image: facebook,
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/rustybucketpub_eltham",
    image: insta,
  },
];

export default () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Logo />
        </div>
        <div className={styles.sectionsContainer}>
          <Bexley />
          <Eltham />
          <FollowUs />
        </div>
      </div>
      <Legal />
    </div>
  );
};

const Heading = ({ title }) => <h2 className={styles.heading}>{title}</h2>;

const Bexley = () => (
  <div className={classNames(styles.section, styles.bexleyContainer)}>
    <div className={styles.sectionContent}>
      <Heading title="Bexley" />
      Arch 3
      <br />
      Mill Row
      <br />
      Bexley
      <br />
      London
      <br />
      DA5 1LA
      <br />
      <a href="https://maps.app.goo.gl/qkpprbM6Sur6VW4w5" target="_blank">
        Google map
      </a>
      <br />
      &nbsp;
      <br />
      <a href="mailto:bexley@therustybucket.pub">bexley@therustybucket.pub</a>
    </div>
  </div>
);

const Eltham = () => (
  <div className={classNames(styles.section, styles.elthamContainer)}>
    <div className={styles.sectionContent}>
      <Heading title="Eltham" />
      11 Court Yard
      <br />
      Eltham
      <br />
      London
      <br />
      SE9 5PR
      <br />
      <a href="https://goo.gl/maps/ENjtRUXUigQaxjbT8" target="_blank">
        Google map
      </a>
      <br />
      &nbsp;
      <br />
      <a href="tel:+442088595500">020 8859 5500</a>
      <br />
      <a href="mailto:eltham@therustybucket.pub">eltham@therustybucket.pub</a>
    </div>
  </div>
);

const FollowUs = () => (
  <div className={classNames(styles.section, styles.followUsContainer)}>
    <div className={styles.socialLinks}>
      {SOCIAL_LINKS.map(({ name, link, image }) => (
        <div className={styles.socialLink} key={name}>
          <a href={link} target="_blank">
            <img src={image} />
          </a>
        </div>
      ))}
    </div>
  </div>
);

const Legal = () => (
  <div className={styles.legal}>
    &copy; The Rusty Bucket {new Date().getFullYear()}
  </div>
);
