import React, { useContext, useEffect, useState } from "react";

import { VenueContext } from "../../VenueContext";
import BeerList from "../BeerList";
import Tile from "../Tile";

import * as styles from "./styles.module.scss";
import { StateContext } from "../../StateContext";

const BeerLists = () => {
  const venue = useContext(VenueContext);
  const state = useContext(StateContext);
  const menus = state.menus.filter(
    (menu) => menu.venue === venue,
  );

  const [currentMenu, setCurrentMenu] = useState(menus[0]);

  useEffect(() => {
    menus && menus.length && setCurrentMenu(menus[0]);
  }, []);

  return (
    <>
      {menus.length > 1 && (
        <div className={styles.buttonsContainer}>
          <div>
            {menus.map((menu) => (
              <Tile
                onClick={() => setCurrentMenu(menu)}
                text={menu.name}
                isActive={
                  currentMenu && currentMenu.untappdId === menu.untappdId
                }
                key={menu.untappdId}
              />
            ))}
          </div>
        </div>
      )}
      <BeerList menu={currentMenu} />
    </>
  );
};

export default BeerLists;
