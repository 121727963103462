import React from "react";

import Beer from "../Beer";

import * as styles from "./styles.module.scss";

export default ({ menu }) => {
  const items = (menu && menu.items) || [];
  return (
    <div className={styles.beerList}>
      <div className={styles.beerListContainer}>
        <div className={styles.letterBoard} />
        <div className={styles.beers}>
          {items.map((item) => (
            <Beer key={item.id} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};
