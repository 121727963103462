import React, { useState } from "react";
import { Link, NavLink } from "../TrackedLink";

import Logo from "../Logo";

import hamburger from "./images/hamburger.svg";

import * as styles from "./styles.module.scss";

export default ({ sections }) => {
  const [isExpandedNavVisible, setIsExpandedNavVisible] = useState(false);

  const toggleNavVisibility = () =>
    setIsExpandedNavVisible(!isExpandedNavVisible);

  return (
    <div className={styles.wrapper}>
      <header className={styles.container}>
        <div className={styles.logoContainer}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className={styles.hamburger}>
          <a onClick={toggleNavVisibility}>
            <img src={hamburger} />
          </a>
        </div>
        <nav className={isExpandedNavVisible ? styles.expanded : ""}>
          <ul>
            {sections.map(({ label, to }) => (
              <li key={label}>
                <NavLink
                  activeClassName={styles.active}
                  to={to}
                  exact
                  onClick={() => setIsExpandedNavVisible(false)}
                >
                  {label}
                </NavLink>
              </li>
            ))}
          </ul>

          <ul className={styles.venue}>
            <li>
              <NavLink
                activeClassName={styles.active}
                to="/bexley"
                onClick={() => setIsExpandedNavVisible(false)}
              >
                Bexley
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles.active}
                to="/eltham"
                onClick={() => setIsExpandedNavVisible(false)}
              >
                Eltham
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
};
