// extracted by mini-css-extract-plugin
var _1 = "qAd3NWKNpizd6Da2UF9E";
var _2 = "tv0GacFKrpSjyogynZJi";
var _3 = "tlQausguJaHtWI7H3Zlo";
var _4 = "aS9Ffneg3Ic025kqiD6S";
var _5 = "myKmL5Y6nyFC9XKCsXce";
var _6 = "CQGwnSyV3jf95ylkAJOB";
var _7 = "uETNpVaxUE7cDALSy7DW";
var _8 = "bClEfRzULcT3jGVvpy9f";
var _9 = "MC9xOg7vRS8soWyC3a5Q";
var _a = "DXPV0c_stRgPBk7PeuL4";
var _b = "rJFiAgeioBchLiMpzwXQ";
var _c = "nvDBswWPuwaYU5rTJwTM";
var _d = "jNfcw9HSeyWnqumvwskQ";
var _e = "m7m1B7dQXlBZpeVcfkvs";
var _f = "xGWSi8eD8J4CjTqdh7ki";
export { _1 as "container", _2 as "dash", _3 as "dayName", _4 as "followUsContainer", _5 as "from", _6 as "heading", _7 as "legal", _8 as "logoContainer", _9 as "openingHoursContainer", _a as "openingHoursWrapper", _b as "section", _c as "sectionsContainer", _d as "socialLink", _e as "to", _f as "wrapper" }
