import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import classNames from "classnames";

import { StateContext } from "../../StateContext";

import Bexley from "../Bexley";
import Eltham from "../Eltham";
import FooterHome from "../FooterHome";
import FooterVenue from "../FooterVenue";
import Home from "../Home";
import ScrollToTop from "../ScrollToTop";

import * as styles from "./styles.module.scss";
import Honeybadger from "../Honeybadger";

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

export default () => {
  useEffect(() => {
    window.ON_PRODUCTION_DOMAIN && ReactGA.initialize("UA-161451416-1");
  }, []);

  const state = JSON.parse(
    document.getElementById("app").getAttribute("state"),
  );

  return (
    <Honeybadger>
      <StateContext.Provider value={state}>
        <Router history={history}>
          <ScrollToTop />
          <TiledBackground>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/bexley" component={Bexley} />
              <Route path="/eltham" component={Eltham} />
            </Switch>
            <Switch>
              <Route path="/" exact component={FooterHome} />
              <Route
                path="/bexley"
                render={() => <FooterVenue location="bexley" />}
              />
              <Route
                path="/eltham"
                render={() => <FooterVenue location="eltham" />}
              />
            </Switch>
          </TiledBackground>
        </Router>
      </StateContext.Provider>
    </Honeybadger>
  );
};

export const TiledBackground = ({ children, storybook = false }) => (
  <div
    className={classNames(styles.container, { [styles.storybook]: storybook })}
  >
    {children}
  </div>
);
