import React from "react";
import { Route, Switch } from "react-router-dom";

import { VenueContext } from "../../VenueContext";
import DrinkInMenu from "../DrinkInMenu";
import Gallery from "../Gallery";
import Header from "../Header";
import VenueHome from "../VenueHome";
import WhatsOn from "../WhatsOn";

const SECTIONS = [
  { label: "Home", to: "/eltham" },
  { label: "Drinks", to: "/eltham/drinks" },
  { label: "What’s On", to: "/eltham/whats-on" },
  { label: "Gallery", to: "/eltham/gallery" },
];

export default () => {
  return (
    <VenueContext.Provider value="Eltham">
      <Header sections={SECTIONS} />
      <Switch>
        <Route
          path="/eltham"
          exact
          render={() => <VenueHome location="eltham" />}
        />
        <Route path="/eltham/drinks" exact component={DrinkInMenu} />
        <Route path="/eltham/gallery" exact component={Gallery} />
        <Route path="/eltham/whats-on" exact component={WhatsOn} />
      </Switch>
    </VenueContext.Provider>
  );
};
