import React from "react";

import Honeybadger from "@honeybadger-io/js";
import ErrorBoundary from "@honeybadger-io/react";

Honeybadger.configure({
  apiKey: window.HONEYBADGER_API_KEY,
  environment: window.ON_PRODUCTION_DOMAIN ? "production" : "staging",
});

export default ({ children }) => (<ErrorBoundary honeybadger={Honeybadger}>{children}</ErrorBoundary>);
