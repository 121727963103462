// extracted by mini-css-extract-plugin
var _1 = "U7qkLyRguDuV1aqorH_M";
var _2 = "N2VqlL60khoyihislYtc";
var _3 = "Ku6kJRHUnQ9pz50Y28pX";
var _4 = "bKGvDJZwMTSMKWKc_f4S";
var _5 = "ucCuaYFYArevXmVKGPIM";
var _6 = "DtNpHEHc4v3vDFjTMEQj";
var _7 = "D4NxFs3gBr93RvdRAo1d";
var _8 = "wgCzLtUoCbVqx0alnvBR";
var _9 = "awSLM6tIcPRAOenyBeBw";
export { _1 as "abv", _2 as "beer", _3 as "brewery", _4 as "containerPrice", _5 as "description", _6 as "dots", _7 as "line", _8 as "name", _9 as "style" }
