import React from "react";
import classNames from "classnames";

import Border from "../Border";

import * as styles from "./styles.module.scss";

export default ({ bottom, center, src, narrowSrc }) => (
  <>
    <div
      className={classNames(styles.container, {
        [styles.bottom]: bottom,
        [styles.center]: center,
      })}
    >
      <img src={src} className={narrowSrc ? styles.wide : undefined} />
      {!!narrowSrc && <img src={narrowSrc} className={styles.narrow} />}
    </div>
    <Border />
  </>
);
