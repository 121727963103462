const DAYS = "Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(
  " ",
);

const MONTHS = "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" ");

const getOrdinal = (numeral) => {
  const lastDigit = numeral % 10;
  if (numeral > 10 && numeral <= 20) return "th";
  if (lastDigit === 1) return "st";
  if (lastDigit === 2) return "nd";
  if (lastDigit === 3) return "rd";
  return "th";
};

export const niceTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const hour = ((date.getHours() + 11) % 12) + 1;
  const meridian = date.getHours() >= 12 ? "pm" : "am";
  const minutes = date.getMinutes() > 0 ? `:${date.getMinutes()}` : "";

  if (hour == 0 && minutes == 0) return "12midnight";
  if (hour == 12 && minutes == 0) return "12noon";

  return `${hour}${minutes}${meridian}`;
};

export const niceDate = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const dayOfWeek = DAYS[date.getDay()];
  const dayOfMonth = date.getDate();
  const ordinal = getOrdinal(dayOfMonth);
  const month = MONTHS[date.getMonth()];

  return `${dayOfWeek} ${dayOfMonth}${ordinal} ${month}`;
};

export const niceDateWithYear = (dateTimeString) => {
  const date = new Date(dateTimeString);

  return `${niceDate(dateTimeString)} ${date.getFullYear()}`;
};
