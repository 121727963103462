import React, { useContext } from "react";

import Content from "../Content";
import FramedTitle from "../FramedTitle";
import Hero from "../Hero";
import Markdown from "../Markdown";
import Notice from "../Notice";

import hero from "../Home/images/pub.webp";
import heroNarrow from "../Home/images/pub-narrow.webp";
import { StateContext } from "../../StateContext";

const VenueHome = ({ location }) => {
    const { contents } = useContext(StateContext);
    const notice = contents[`${location}Home`];
  const title = location.charAt(0).toUpperCase() + location.slice(1);

  return (
    <>
      <Hero src={hero} narrowSrc={heroNarrow} />
      <Content>
        <FramedTitle text={title} />
          <Notice title="Hi there!" frameStyle="brown">
            <Markdown>{notice}</Markdown>
          </Notice>
      </Content>
    </>
  );
};

export default VenueHome;
