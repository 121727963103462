import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";

import { VenueContext } from "../../VenueContext";
import DrinkInMenu from "../DrinkInMenu";
import Header from "../Header";
import VenueHome from "../VenueHome";
import WhatsOn from "../WhatsOn";
import { StateContext } from "../../StateContext";

const SECTIONS = [
  { label: "Home", to: "/bexley" },
  { label: "Drinks", to: "/bexley/drinks" },
];

export default () => {
  const state = useContext(StateContext);
  const events =
    state.events.past.filter((event) => event.venue === "Bexley").length +
    state.events.upcoming.filter((event) => event.venue === "Bexley").length;
  const sections =
    events === 0
      ? SECTIONS
      : SECTIONS.concat({ label: "What’s On", to: "/bexley/whats-on" });
  return (
    <VenueContext.Provider value="Bexley">
      <Header sections={sections} />
      <Switch>
        <Route
          path="/bexley"
          exact
          render={() => <VenueHome location="bexley" />}
        />
        <Route path="/bexley/drinks" exact component={DrinkInMenu} />
        <Route path="/bexley/whats-on" exact component={WhatsOn} />
      </Switch>
    </VenueContext.Provider>
  );
};
