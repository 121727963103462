import React, { useEffect, useState } from "react";
import axios from "axios";
import classNames from "classnames";

import Logo from "../Logo";

import facebook from "./images/facebook.svg";
import insta from "./images/insta.svg";

import * as styles from "./styles.module.scss";

const BEXLEY = 47313;
const ELTHAM = 17275;

const locationIds = {
  bexley: BEXLEY,
  eltham: ELTHAM,
};

const SOCIAL_LINKS = [
  {
    name: "Facebook",
    link: {
      bexley: "https://www.facebook.com/share/arisZoPq6NQdaB8C",
      eltham: "https://www.facebook.com/1493776157328385",
    },
    image: facebook,
  },
  {
    name: "Instagram",
    link: {
      bexley: "https://www.instagram.com/rustybucketpub_bexley",
      eltham: "https://www.instagram.com/rustybucketpub_eltham",
    },
    image: insta,
  },
];

const to12Hour = (hour) => {
  const result = hour % 12;
  return result == 0 ? 12 : result;
};

const readableTime = (date, shouldShowMinutes) => {
  let output = to12Hour(new Date(date).getHours());
  if (!shouldShowMinutes) {
    output += `:${new Date(date).getMinutes().toString().padStart(2, "0")}`;
  }
  return output;
};

const parseHours = (hours) => {
  const allOnTheHour =
    hours.find(
      ({ open_at, close_at, closed }) =>
        !closed &&
        (new Date(open_at).getMinutes() > 0 ||
          new Date(close_at).getMinutes() > 0),
    ) === undefined;
  const days = hours.map(({ open_at, close_at, day, closed }) => ({
    day: day.charAt(0).toUpperCase() + day.slice(1).toLowerCase(),
    from: closed ? "" : readableTime(open_at, allOnTheHour),
    to: closed ? "" : readableTime(close_at, allOnTheHour),
  }));
  const daysOfWeek =
    "monday tuesday wednesday thursday friday saturday sunday".split(" ");
  return days.sort((a, b) => {
    if (
      daysOfWeek.indexOf(a.day.toLowerCase()) <
      daysOfWeek.indexOf(b.day.toLowerCase())
    )
      return -1;
    if (
      daysOfWeek.indexOf(a.day.toLowerCase()) >
      daysOfWeek.indexOf(b.day.toLowerCase())
    )
      return 1;
    return 0;
  });
};

export default ({ location }) => {
  const hoursApi = () =>
    `https://business.untappd.com/api/v1/locations/${locationIds[location]}/hours`;

  const [openingHours, setOpeningHours] = useState([]);

  useEffect(() => {
    axios
      .create({ headers: { Authorization: `Basic ${window.UTFB_AUTH_TOKEN}` } })
      .get(hoursApi())
      .then(({ data }) => {
        const hours = data.hours;
        setOpeningHours(parseHours(hours));
      })
      .catch(() =>
        setOpeningHours([
          { day: "Monday", from: "12", to: "10" },
          { day: "Tuesday", from: "12", to: "10" },
          { day: "Wednesday", from: "12", to: "11" },
          { day: "Thursday", from: "12", to: "11" },
          { day: "Friday", from: "11", to: "11" },
          { day: "Saturday", from: "11", to: "11" },
          { day: "Sunday", from: "12", to: "10" },
        ]),
      );
  }, [location]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Logo />
        </div>
        <div className={styles.sectionsContainer}>
          <OpeningHours openingHours={openingHours} />
          <FindUs location={location} />
          <TalkToUs location={location} />
          <FollowUs location={location} />
        </div>
      </div>
      <Legal />
    </div>
  );
};

const Heading = ({ title }) => <h2 className={styles.heading}>{title}</h2>;

const OpeningHours = ({ openingHours }) => (
  <div className={styles.section}>
    <Heading title="Opening Hours" />
    <div className={styles.openingHoursWrapper}>
      <div className={styles.openingHoursContainer}>
        {openingHours.map(({ day, from, to }) => (
          <Day key={day} day={day} from={from} to={to} />
        ))}
      </div>
    </div>
  </div>
);

const Day = ({ day, from, to }) => (
  <>
    <div className={styles.dayName}>{day}</div>
    <div className={styles.from}>{from}</div>
    <div className={styles.dash}>-</div>
    <div className={styles.to}>{to}</div>
  </>
);

const FindUs = ({ location }) => {
  const content = {
    bexley: {
      address: "Arch 3, Mill Row, Bexley, London, DA5 1LA",
      gmap: "https://maps.app.goo.gl/qkpprbM6Sur6VW4w5",
    },
    eltham: {
      address: "11 Court Yard, Eltham, London, SE9 5PR",
      gmap: "https://goo.gl/maps/ENjtRUXUigQaxjbT8",
    },
  }[location];

  return (
    <div className={styles.section}>
      <Heading title="Find Us" />
      {content.address.split(", ").map((x) => (
        <>
          {x}
          <br />
        </>
      ))}
      &nbsp;
      <br />
      <a href={content.gmap} target="_blank">
        Google map
      </a>
    </div>
  );
};

const TalkToUs = ({ location }) => (
  <div className={styles.section}>
    <Heading title="Talk To Us" />
    <a href={`mailto:${location}@therustybucket.pub`}>
      {location}@therustybucket.pub
    </a>
    {location === "eltham" && (
      <>
        <br />
        020 8859 5500
      </>
    )}
  </div>
);

// TODO: a11y links
const FollowUs = ({ location }) => (
  <div className={classNames(styles.section, styles.followUsContainer)}>
    <Heading title="Follow Us" />
    {SOCIAL_LINKS.map(({ name, link, image }) => (
      <div className={styles.socialLink} key={name}>
        <a href={link[location]} target="_blank">
          <img src={image} />
        </a>
      </div>
    ))}
  </div>
);

const Legal = () => (
  <div className={styles.legal}>
    &copy; The Rusty Bucket {new Date().getFullYear()}
  </div>
);
