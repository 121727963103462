import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "../TrackedLink";

const trackMailToClick = () =>
  ReactGA.event({ category: "Click", action: "MailTo", label: "Link" });

export default ({ children }) => (
  <ReactMarkdown
    renderers={{
      link: ({ href, children: linkChildren }) =>
        href.indexOf("mailto:") == -1 ? (
          <Link to={href}>{linkChildren}</Link>
        ) : (
          <a onClick={() => trackMailToClick()} href={href}>
            {linkChildren}
          </a>
        ),
    }}
  >
    {children}
  </ReactMarkdown>
);
