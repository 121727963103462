import React from "react";
import ReactGA from "react-ga";
import { Link as RRLink, NavLink as RRNavLink } from "react-router-dom";

const track = ({ label, to }) => {
  ReactGA.event({ category: "Click", action: to, label });
};

export const Link = ({ label = "Link", to, ...props }) => (
  <RRLink
    onClick={() => {
      track({ label, to, label });
    }}
    to={to}
    {...props}
  />
);

export const NavLink = ({ label = "NavLink", to, ...props }) => (
  <RRNavLink
    onClick={() => {
      track({ label, to, label });
    }}
    to={to}
    {...props}
  />
);
