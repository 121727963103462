import React from "react";
import classNames from "classnames";

import brownFrame from "./images/brown-frame.jpg";
import goldFrame from "./images/gold-frame.jpg";

import * as styles from "./styles.module.scss";

const getFrameImage = (style) =>
  ({ brown: brownFrame, gold: goldFrame }[style]);

export const STYLES = ["gold", "brown"];

export default ({
  children,
  fullHeight = false,
  fullWidth = false,
  style = "gold",
}) => (
  <div
    className={classNames(styles.container, styles[style], {
      [styles.fullHeight]: fullHeight,
      [styles.fullWidth]: fullWidth,
    })}
  >
    <div className={styles.frameTopLeft}>
      <img src={getFrameImage(style)} />
    </div>
    <div className={styles.frameTopRight}>
      <img src={getFrameImage(style)} />
    </div>
    <div className={styles.frameBottomRight}>
      <img src={getFrameImage(style)} />
    </div>
    <div className={styles.frameBottomLeft}>
      <img src={getFrameImage(style)} />
    </div>
    <div className={styles.contentContainer}>{children}</div>
  </div>
);
