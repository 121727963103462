import React, { useEffect, useState } from "react";

export default ({ text }) => {
  let interval;
  let animatedText;

  const [output, setOutput] = useState("");

  useEffect(() => {
    clearInterval(interval);
    setOutput("");
    animatedText = "";
    interval = setInterval(() => {
      if (animatedText.length === text.length) {
        clearInterval(interval);
        return;
      }
      animatedText += text[animatedText.length];
      let gibberish = "";
      for (let i = 0; i < text.length - animatedText.length; i++) {
        if (text.charAt(animatedText.length + i) === " ") {
          gibberish += " ";
        } else {
          let letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
          gibberish += letters[Math.floor(Math.random() * letters.length)];
        }
      }
      setOutput(animatedText + gibberish);
    }, 30);
  }, [text]);

  useEffect(() => () => clearInterval(interval), []);

  return <>{output}</>;
};
