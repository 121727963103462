// extracted by mini-css-extract-plugin
var _1 = "yAPja2i5ZH2GyI3gZayu";
var _2 = "mDAmwW4lfWLGBInOcvfb";
var _3 = "TM2Cb77L2ZKlfvFYp6Rw";
var _4 = "Sa6dtLDponPZIB74Sicg";
var _5 = "xe1vhdO_vZnKLOPN7sba";
var _6 = "OxTZOaal7be70msBjllM";
var _7 = "th0tuec8qTstsKcSHkEw";
var _8 = "qqTR_BSZQ60SjVrnbV3j";
var _9 = "kAdtUos7D0QHaGlsWyMR";
export { _1 as "brown", _2 as "container", _3 as "contentContainer", _4 as "frameBottomLeft", _5 as "frameBottomRight", _6 as "frameTopLeft", _7 as "frameTopRight", _8 as "fullHeight", _9 as "fullWidth" }
