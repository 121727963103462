import React from "react";

import Content from "../Content";
import Framed from "../Framed";
import Header from "../Header";
import Hero from "../Hero";
import Panel from "../Panel";
import { Link } from "../TrackedLink";

import bexleyImage from "./images/bexley.webp";
import elthamImage from "./images/eltham.webp";
import hero from "./images/pub.webp";
import heroNarrow from "./images/pub-narrow.webp";

import * as styles from "./styles.module.scss";

export default () => (
  <>
    <Header sections={[]} />
    <Hero src={hero} narrowSrc={heroNarrow} />
    <Content>
      <div className={styles.venues}>
        <div className={styles.venue}>
          <Link to="/bexley">
            <div className={styles.imageContainer}>
              <Framed>
                <img src={bexleyImage} className={styles.venueImage} />
              </Framed>
            </div>
            <div className={styles.button}>
              <Panel text="Bexley" />
            </div>
          </Link>
        </div>
        <div className={styles.venue}>
          <Link to="/eltham">
            <div className={styles.imageContainer}>
              <Framed>
                <img src={elthamImage} className={styles.venueImage} />
              </Framed>
            </div>
            <div className={styles.button}>
              <Panel text="Eltham" />
            </div>
          </Link>
        </div>
      </div>
    </Content>
  </>
);
